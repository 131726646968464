var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"color":'#aa945b',"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('b',[_vm._v("Possible Duplicates "),_c('span',{staticStyle:{"color":"green"}},[_vm._v("("+_vm._s(_vm.total)+")")])])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pageLength,"align":"end"},on:{"update:activePage":[_vm.linkGen,function($event){_vm.activePage=$event}],"update:active-page":function($event){_vm.activePage=$event}}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"items":_vm.possibleDuplicates.duplicates,"border":"","fields":_vm.fields,"noItemsView":{ noResults: "No Questions Found", noItems: "No Questions Found" },"hover":"","sorter":""},scopedSlots:_vm._u([{key:"index",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"quiz_question_1",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('CRow',[_c('CCol',[(item.quiz_question_1.question_type == 'fill_in_multiple_blanks_question')?_c('multipleBlanks',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'short_answer_question')?_c('shortAnswer',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'matching_question')?_c('matching',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'true_false_question')?_c('trueFalse',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'multiple_answers_question')?_c('multipleAnswers',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'multiple_choice_question')?_c('multipleChoice',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'multiple_dropdowns_question')?_c('multipleDropDowns',{attrs:{"question":item.quiz_question_1}}):(item.quiz_question_1.question_type == 'numerical_question')?_c('numerical',{attrs:{"question":item.quiz_question_1}}):_c('div',[_vm._v(_vm._s(item.quiz_question_1.question_type))]),(item.quiz_question_1.tags.length)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticStyle:{"color":"green","font-weight":"bold"}},[_vm._v("Tags:")]),_vm._l((item.quiz_question_1.tags),function(tag,t_index){return _c('span',{key:t_index,staticClass:"mr-2"},[_c('CBadge',{staticStyle:{"font-size":"14px","cursor":"pointer","margin-top":"10px"},attrs:{"color":"success"}},[_vm._v(_vm._s(tag))])],1)})],2):_vm._e(),_c('div',{staticClass:"mt-3 text-right"},[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.keepQuestion(item.id, item.quiz_question_2_id)}}},[_vm._v("Delete Duplicate")])])],1)],1)],1)]}},{key:"quiz_question_2",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('CRow',[_c('CCol',[(item.quiz_question_2.question_type == 'fill_in_multiple_blanks_question')?_c('multipleBlanks',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'short_answer_question')?_c('shortAnswer',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'matching_question')?_c('matching',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'true_false_question')?_c('trueFalse',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'multiple_answers_question')?_c('multipleAnswers',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'multiple_choice_question')?_c('multipleChoice',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'multiple_dropdowns_question')?_c('multipleDropDowns',{attrs:{"question":item.quiz_question_2}}):(item.quiz_question_2.question_type == 'numerical_question')?_c('numerical',{attrs:{"question":item.quiz_question_2}}):_c('div',[_vm._v(_vm._s(item.quiz_question_2.question_type))]),(item.quiz_question_2.tags.length)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticStyle:{"color":"green","font-weight":"bold"}},[_vm._v("Tags:")]),_vm._l((item.quiz_question_2.tags),function(tag,t_index){return _c('span',{key:t_index,staticClass:"mr-2"},[_c('CBadge',{staticStyle:{"font-size":"14px","cursor":"pointer","margin-top":"10px"},attrs:{"color":"success"}},[_vm._v(_vm._s(tag))])],1)})],2):_vm._e(),_c('div',{staticClass:"mt-3 text-right"},[_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.keepQuestion(item.id, item.quiz_question_1_id)}}},[_vm._v("Delete Duplicate")])])],1)],1)],1)]}},{key:"similarity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center align-top"},[_vm._v(" "+_vm._s(Math.round(item.similarity))+"% ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"text-center align-top"},[_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_vm._v("Keep Both")])])]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pageLength,"align":"end"},on:{"update:activePage":[_vm.linkGen,function($event){_vm.activePage=$event}],"update:active-page":function($event){_vm.activePage=$event}}})],1)],1)],1)],1)],1)],1),_c('CModal',{attrs:{"title":"Confirmation","color":"success","centered":"","show":_vm.deleteConfirmationModal},on:{"update:show":function($event){_vm.deleteConfirmationModal=$event}}},[_vm._v(" Are you sure you want to delete from deduplication list? "),_c('template',{slot:"footer"},[_c('CButton',{attrs:{"color":"secondary"},on:{"click":_vm.cancelConfirmModal}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":_vm.deleteDeduplication}},[_vm._v("OK")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }